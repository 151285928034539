<template>
  <b-container class="assets-board">
    <!--      Upper screen section-->
    <br />
    <div v-if="$store.getters.stateAreas.length > 0" class="welcome-tittle-assets">
      <span>
        {{
          $store.getters.stateAreas.find(
            (area) => area.id === $route.params.area_id
          ).name
        }}
        >
        {{
          $store.getters.stateCategories.find(
            (category) => category.id === $route.params.category_id
          ).name
        }}
        > Assets disponibles
        <b-button
          :disabled="loading"
          variant="link"
          class="link-button-custom welcome-tittle-assets float-right"
          @click="goBack"
        >
          Regresar
        </b-button>
      </span>
      <br />
      <br />
      Assets disponibles dentro de la categoria "{{
        $store.getters.stateCategories.find(
          (category) => category.id === $route.params.category_id
        ).name
      }}" > id:
      {{ $route.params.category_id }}
    </div>
    <br />

    <b-row>
      <!--      Left section for images shown-->
      <b-col cols="5" sm="6" md="8">
        <!--      Upper button change order/ Save positions-->
        <b-button
          block
          variant="outline-primary"
          v-if="!disableOrderChange"
          :disabled="loading"
          @click="updatePosition"
        >
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Guardar cambios </span>
        </b-button>
        <b-button
          block
          variant="outline-primary"
          v-else
          :disabled="loading"
          @click="disableOrderChange = false"
        >
          Cambiar orden de imagenes
        </b-button>
        <div class="welcome-tittle-assets" v-if="!disableOrderChange">
          <br />
          <span>
            Arrastra y suelta las imagenes para cambiar el orden en el que se
            muestran, guarda los cambios despues de lograr el orden perfecto.
          </span>
        </div>
        <br />

        <!--          Draggable section for available assets-->
        <draggable
          v-model="images"
          :move="onMoveCallback"
          :disabled="disableOrderChange"
          class="row"
        >
          <b-col
            cols="12"
            md="6"
            xl="4"
            v-for="(image, index) in images"
            :key="image.id"
            class="assets-board-images"
          >
            <b-button
              variant="link"
              :disabled="loading"
              block
              active-class="active"
              @click="selectImage(index)"
            >
              <b-card bg-variant="dark" text-variant="white" no-body>
                <div class="assets-board-image-text">
                  <b-row>
                    <b-col cols="12" sm="9" lg="10">
                      Position: {{ index }}
                    </b-col>
                    <b-col cols="12" sm="3" lg="2">
                      <b-button
                        :disabled="loading"
                        variant="link"
                        class="assets-nav-active-tab link-button-custom"
                        @click="deleteImage(image)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <b-card-img
                  :src="image.url"
                  alt="Image"
                  bottom
                  img-bottom
                  v-if="!isVideo(image.url)"
                />
                <b-embed type="video" aspect="16by9" :src="image.url" v-else />
              </b-card>
            </b-button>
          </b-col>
        </draggable>
      </b-col>

      <!--        Right section for image set up & preview-->
      <b-col cols="7" sm="6" md="4" class="assets-board-nav">
        <!--          New image button-->
        <b-button
          block
          variant="primary"
          :disabled="loading"
          v-b-modal.modal-create
        >
          <b-row>
            <b-col cols="2"> + </b-col>
            <b-col cols="10"> Agregar nuevo asset </b-col>
          </b-row>
        </b-button>
        <br />

        <!--          Image set up/Preview section-->
        <b-card
          text-variant="white"
          class="assets-card-element assets-board-nav-inputs"
        >
          <div class="assets-board-nav-inputs-label" v-if="!disableOrderChange">
            <br />
            La configuracion de imagenes no esta disponible mientras re-ordenas
            las posiciones.
          </div>
          <!--            Content is separated by cards/tabs-->
          <!--              Tab where set up happens-->
          <b-tabs
            card
            fill
            active-nav-item-class="assets-nav-active"
            v-model="navIndex"
            v-else
          >
            <b-tab>
              <template #title>
                <div class="assets-nav-active-tab">Set up</div>
              </template>
              <!-- If image has ben selected show config section, if not show help text-->
              <div v-if="image.id != null && !updated">
                <div class="assets-board-nav-inputs-label">
                  Asset seleccionada
                </div>
                <b-row>
                  <b-col cols="12" xl="8">
                    <b-img
                      left
                      fluid-grow
                      :src="image.url"
                      class="assets-board-selected"
                      v-if="!isVideo(image.url)"
                    />
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="image.url"
                      v-else
                    />
                  </b-col>
                </b-row>
                <br />
                <div class="assets-board-nav-inputs-label">
                  Google ads ID
                </div>
                <b-form-input
                    :disabled="loading"
                    v-model="image.google_ads"
                    placeholder="Identificador"
                    class="assets-board-nav-inputs"
                    autocomplete="off"
                />
                <br />
                <div class="assets-board-nav-inputs-label">
                  Url de redireccion
                </div>
                <b-form-input
                  :disabled="loading"
                  v-model="image.redirection_url"
                  placeholder="Url"
                  class="assets-board-nav-inputs"
                  autocomplete="off"
                />
                <br />
                <div class="assets-board-nav-inputs-label">
                  Fecha y hora de expiracion
                </div>
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-datepicker
                      v-model="image.date"
                      reset-button
                      class="assets-board-nav-inputs-layer"
                      hide-header
                      label-no-date-selected="-"
                      :disabled="loading"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-timepicker
                      v-model="image.time"
                      reset-button
                      class="assets-board-nav-inputs-layer"
                      hide-header
                      label-no-time-selected="-"
                      :disabled="loading"
                    />
                  </b-col>
                </b-row>
                <br />
                <div class="assets-board-nav-inputs-label">Dimensiones</div>
                <b-form-input
                    disabled
                    v-model="image.dimensions"
                    placeholder="Dimensiones"
                    class="assets-board-nav-inputs text-color-light"
                />
                <br />
                <div class="assets-board-nav-inputs-label">
                  Disponibilidad en la semana
                </div>
                <b-form-checkbox
                  v-model="allSelected"
                  @change="toggleAll"
                  switch
                  class="assets-board-nav-inputs text-left"
                  :disabled="loading"
                >
                  {{ allSelected ? "Deseleccionar todo" : "Seleccionar todo" }}
                </b-form-checkbox>
                <br />
                <b-form-checkbox-group
                  v-model="image.week_availability"
                  stacked
                  button-variant="dark"
                  :options="options"
                  class="assets-board-nav-inputs text-left"
                  :disabled="loading"
                />
                <br />
                <b-row>
                  <b-col cols="12" sm="6">
                    <b-button
                      variant="link"
                      block
                      :disabled="loading"
                      @click="cancelSelect"
                      class="assets-tabs-options link-button-custom"
                    >
                      Cancelar
                    </b-button>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-button
                      variant="primary"
                      :disabled="loading"
                      block
                      @click="updateImage"
                      class="assets-tabs-options"
                    >
                      <span v-if="loading">
                        <b-spinner variant="dark" small></b-spinner>
                      </span>
                      <span v-else> Guardar </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <br />
                <div class="assets-board-nav-inputs-label">
                  Porfavor selecciona una imagen para visualizar las
                  configuraciones disponibles.
                </div>
              </div>
            </b-tab>

            <!--                Tab for preview images-->
            <b-tab active>
              <template #title>
                <div class="assets-nav-active-tab">Preview</div>
              </template>
              <br />
              <div class="assets-board-nav-inputs-label">
                Selecciona una fecha para previsualizar
              </div>
              <b-form-datepicker
                v-model="previewDate"
                placeholder="Preview date"
                reset-button
                class="assets-board-nav-inputs-layer"
                hide-header
                :disabled="loading"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              >
              </b-form-datepicker>
              <br />
              <b-carousel fade indicators img-width="1024" img-height="480">
                <b-carousel-slide
                  v-for="(previewImage, previewIndex) in previewImages"
                  :key="'pv-' + previewIndex"
                  :img-src="previewImage.url"
                >
                </b-carousel-slide>
                <b-carousel-slide
                  img-blank
                  img-alt="Blank image"
                  v-if="previewImages.length < 1"
                >
                  <p>Selecciona una fecha para visualizar</p>
                </b-carousel-slide>
              </b-carousel>
              <br />
              <b-button
                block
                variant="primary"
                :disabled="loading"
                @click="getPreviewImages"
              >
                <span v-if="loading">
                  <b-spinner variant="dark" small></b-spinner>
                </span>
                <span v-else> Preview </span>
              </b-button>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>

    <!--Modal for adding a new Image-->
    <b-modal
      v-model="showCreate"
      id="modal-create"
      centered
      content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Agregar nueva imagen</h4>
      </template>
      <div class="assets-board-nav-inputs-label">Imagen a subir</div>
      <b-form-file
        v-model="fileToUpload.image"
        :state="Boolean(fileToUpload.image)"
        placeholder="Choose a file..."
        drop-placeholder="Drop file here..."
        class="assets-board-nav-inputs-layer"
      >
      </b-form-file>
      <br />
      <br />
      <div class="assets-board-nav-inputs-label">Dimensiones</div>
      <b-form-input
          disabled
          v-model="fileToUpload.dimensions"
          placeholder="Dimensiones"
          class="assets-board-nav-inputs text-color-light"
      />
      <template #modal-footer>
        <b-button
          :disabled="loading"
          variant="link"
          class="link-button-custom"
          @click="cancelUpload"
        >
          Cancelar
        </b-button>
        <b-button
          pill
          :disabled="loading"
          variant="primary"
          @click="uploadFile"
        >
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Subir </span>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "AssetsBoard",
  components: { draggable },
  computed: {
    loading() {
      return this.$store.getters.isLoadingConf;
    },
    images: {
      get() {
        return this.$store.getters.stateImages;
      },
      set(value) {
        this.$store.commit("setImages", value);
      },
    },
    previewImages: {
      get() {
        return this.$store.getters.statePreviewImages;
      },
      set(value) {
        this.$store.commit("setPreviewImages", value);
      },
    },
  },
  data() {
    return {
      navIndex: 1,
      showCreate: false,
      allSelected: false,
      updated: false,
      disableOrderChange: true,
      fileToUpload: {
        image: null,
        dimensions: this.$store.getters.stateCategories.find(
            (category) => category.id === this.$route.params.category_id
        ).assets_dimensions,
      },
      previewDate: "",
      options: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      image: {
        id: null,
        redirection_url: "",
        dimensions: "",
        expiration_date: "",
        date: "",
        time: "",
        week_availability: [],
        url: "",
        google_ads: "",
      }
    };
  },
  methods: {
    async updatePosition() {
      this.images.forEach((image, index) => {
        image.image_conf.display_position = index;
      });

      await this.$store.dispatch(
        "updateImagePosition",
        this.$store.getters.stateToken
      );

      this.updated = false;
      this.navIndex = 1;
      this.disableOrderChange = true;
    },
    async updateImage() {
      this.image.expiration_date =
        this.image.date !== "" && this.image.time !== ""
          ? this.image.date + " " + this.image.time
          : null;

      await this.$store.dispatch("updateImageConf", {
        token: this.$store.getters.stateToken,
        data: this.image,
      });

      this.previewImages = [];
    },
    deleteImage(image) {
      this.$bvModal
        .msgBoxConfirm("Estas seguro de que deseas eliminar la imagen?", {
          contentClass: "modal-dark",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch("deleteImage", {
              token: this.$store.getters.stateToken,
              data: image,
            });
          }
        })
        .catch(() => {
          alert("Opps... algo ha salido mal, intentelo mas tarde");
        });

      this.previewImages = [];
      this.navIndex = 1;
      this.cancelSelect();
    },
    async uploadFile() {
      let imageInfo = new FormData();
      //Main File settings
      imageInfo.append("image", this.fileToUpload.image);
      imageInfo.append("dimensions", this.fileToUpload.dimensions);
      imageInfo.append("category_id", this.$route.params.category_id);
      imageInfo.append("area_id", this.$route.params.area_id);

      await this.$store.dispatch("uploadImage", {
        token: this.$store.getters.stateToken,
        imageInfo: imageInfo,
      });

      this.fileToUpload = {
        image: null,
        dimensions: this.$store.getters.stateCategories.find(
            (category) => category.id === $route.params.category_id
        ).assets_dimensions,
      };

      this.updated = true;
      this.disableOrderChange = false;
    },
    async getPreviewImages() {
      await this.$store.dispatch("getPreviewImages", {
        token: this.$store.getters.stateToken,
        date: this.previewDate,
        area: this.$route.params.area_id,
        category: this.$route.params.category_id,
      });
    },
    onMoveCallback() {
      this.updated = true;
      this.cancelSelect();
    },
    toggleAll(checked) {
      this.image.week_availability = checked ? this.options.slice() : [];
    },
    cancelUpload() {
      this.showCreate = false;
      this.fileToUpload = {
        image: null,
        dimensions: this.$store.getters.stateCategories.find(
            (category) => category.id === $route.params.category_id
        ).assets_dimensions,
      }
    },
    selectImage(index) {
      this.image.id = this.images[index].id;
      this.image.week_availability = this.images[index].image_conf.week_availability;
      this.image.redirection_url = this.images[index].image_conf.redirection_url;
      this.image.expiration_date = this.images[index].image_conf.expiration_date;
      this.image.google_ads = this.images[index].image_conf.google_ads;
      this.image.dimensions = this.images[index].image_conf.dimensions;
      this.image.url = this.images[index].url;
      this.navIndex = 0;
    },
    cancelSelect() {
      this.image = {
        id: null,
        redirection_url: "",
        dimensions: "",
        expiration_date: "",
        date: "",
        time: "",
        week_availability: [],
        url: "",
        google_ads: ""
      };
    },
    isVideo(url) {
      let fileType = url.substring(url.lastIndexOf(".") + 1);
      let imageFileExtensions = ['png', 'jpe', 'jpeg', 'jpg', 'gif', 'bmp', 'ico', 'tiff', 'tif', 'svg', 'svgz'];
      return imageFileExtensions.indexOf(fileType) === -1;
    },
    goBack() {
      this.$store.commit("setPreviewImages", []);
      this.$store.commit("setCategories", []);
      this.$store.commit("setImages", []);
      this.$router.push({
        name: "Categories",
        params: { area_id: this.$route.params.area_id },
      });
    },
  },
  mounted() {
    if (this.$store.getters.stateAreas.length === 0 || this.$store.getters.stateCategories.length === 0) {
      this.$router.push({ name: "Board" });
    }

    this.$store.dispatch("getAvailableImages", {
      token: this.$store.getters.stateToken,
      area: this.$route.params.area_id,
      category: this.$route.params.category_id,
    });
  },
};
</script>

<style scoped>
.assets-board {
  max-width: 95%;
}

.welcome-tittle-assets {
  color: white;
  text-align: left;
  font-size: 16px;
}

.welcome-tittle-assets span {
  color: #7c849f;
  text-align: left;
  font-size: 14px;
}

.assets-board-images {
  width: 100%;
  height: 100%;
  padding-bottom: 2%;
}

.assets-board-images button {
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.link-button-custom {
  color: #7c849f;
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
}

.link-button-custom:focus {
  color: #7c849f;
  text-decoration: none;
  outline: none;
  border: none;
  box-shadow: none;
}

.assets-board-images button:focus {
  border: 1px solid white;
  box-shadow: 0 0 2px 2px white;
  outline: none;
}

.assets-board-selected {
  border: 3px solid #292f3a;
  margin-bottom: 10px;
}

.assets-card-element {
  width: 100%;
  height: 100%;
  border-color: white;
  padding: 0.4em;
}

.assets-board-nav-inputs {
  background-color: #292f3a;
  color: white;
}

.assets-board-nav-inputs-label {
  color: #7c849f;
  font-size: 14px;
  text-align: left;
  padding-bottom: 2%;
}

.assets-nav-active-tab {
  color: #7c849f;
  border: none;
}

.assets-tabs-options {
  margin-bottom: 10%;
}

.assets-board-image-text {
  margin: 3%;
  text-align: left;
  font-weight: bold;
}
</style>

<style>
.assets-board-nav-inputs-layer {
  background-color: #292f3a !important;
  color: white !important;
}

.assets-board-nav-inputs-layer * {
  background-color: #292f3a !important;
  color: white !important;
}

.assets-nav-active {
  background-color: #292f3a !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 3px solid #2789e9 !important;
}

.assets-nav-active div {
  color: #2789e9 !important;
}

.text-color-light{
  color: #7c849f !important;
}
</style>
